import axios from "axios"
import { Configuration } from "./configuration"
import { AppBrandControllerApiFactory, AppFashionStyleControllerApiFactory, AppHomeControllerApiFactory, AppPaymentControllerApiFactory, AppProductControllerApiFactory, AppPromotionControllerApiFactory, AppSignupControllerApiFactory, AppUserControllerApiFactory, AppUserCuratingControllerApiFactory, AppUserOfferControllerApiFactory, AppUserOrderControllerApiFactory, CommonImageControllerApiFactory } from "./api";

export const API_ADDRESS = "https://api.disco-fashion.com";

class APIClientClass {
    public client = axios.create({
        withCredentials: false,
        timeout: 10000,
        headers: {
            'Access-Control-Allow-Origin': 'https://local.disco-fashion.com:3000',
            'Access-Control-Allow-Credentials': true
        }
    })
    private readonly configuration = new Configuration();

    readonly AppUser = AppUserControllerApiFactory(
        this.configuration,
        API_ADDRESS,
        this.client
    )
    readonly AppUserOffer = AppUserOfferControllerApiFactory(
        this.configuration,
        API_ADDRESS,
        this.client
    )
    readonly AppUserCurating = AppUserCuratingControllerApiFactory(
        this.configuration,
        API_ADDRESS,
        this.client
    )
    readonly AppHome = AppHomeControllerApiFactory(
        this.configuration,
        API_ADDRESS,
        this.client
    )
    readonly AppSignUp = AppSignupControllerApiFactory(
        this.configuration,
        API_ADDRESS,
        this.client
    )
    readonly AppProduct = AppProductControllerApiFactory(
        this.configuration,
        API_ADDRESS,
        this.client
    )
    readonly AppPayment = AppPaymentControllerApiFactory(
        this.configuration,
        API_ADDRESS,
        this.client
    )
    readonly AppFashionStyle = AppFashionStyleControllerApiFactory(
        this.configuration,
        API_ADDRESS,
        this.client
    )
    readonly AppBrand = AppBrandControllerApiFactory(
        this.configuration,
        API_ADDRESS,
        this.client
    )
    readonly CommonImage = CommonImageControllerApiFactory(
        this.configuration,
        API_ADDRESS,
        this.client
    )
    readonly AppOrder = AppUserOrderControllerApiFactory(
        this.configuration,
        API_ADDRESS,
        this.client
    )
    readonly AppPromotion = AppPromotionControllerApiFactory(
        this.configuration,
        API_ADDRESS,
        this.client
    )
}

export const APIClient = new APIClientClass()
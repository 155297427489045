import { getCookie } from "cookies-next";
import { APIClient } from "../api/client";

const TOAST_BLACKLIST = [""];

export const handleAPIError = () => {
  APIClient.client.interceptors.response.use(
    (res) => res,
    (err) => {
      
      const LOGIN_TOAST_FLAG = TOAST_BLACKLIST.map((i) =>
        err.config.url.includes(i)
      ).includes(true);

      if (LOGIN_TOAST_FLAG) {
        //! IGNORE THIS FLAG
      } else {
        // SHOW ERROR TOAST
      }

      if (err.response.status === 403 && !err.response.config.url.includes("fashion-style")) {
        window.location.href = "/onboard"
      }

      return Promise.reject(err)
    }
  );
};

export const handleAPIRequest = () => {
  APIClient.client.interceptors.request.use(
    function (config) {
      // 스토리지에서 토큰을 가져온다.
      const token = getCookie("token");

      // 토큰이 있으면 요청 헤더에 추가한다.
      if (token) {
        config.headers["Authorization"] = `${token}`;
      }
      return config;
    },
    function (error) {
      // 요청 오류가 있는 작업 수행
      return Promise.reject(error);
    }
  );
};
